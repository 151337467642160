import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import HexaPattern from './hexa.svg';
import ufoImage from './ufo.png';
import s from './Error404.module.scss';

const Error404 = () => (
  <Layout>
    <div className={s.root}>
      <div className={s.container}>
        <div className={s.head}>
          <img className={s.emoji} src={ufoImage} alt="ufo-emoji" />
          <div>
            <h1 className={s.mainHeading}>404</h1>
            <h4 className={s.subHeading}>We sincerely apologize.</h4>
            <p className={s.description}>
              {
                "The page you're looking for is no longer here. Maybe it was never here in the first place. Go back to page or visit home page."
              }
            </p>
            <div className={s.goHomeBtn}>
              <Link to="/">
                <Button theme="grey">Go home</Button>
              </Link>
            </div>
          </div>
          <HexaPattern className={s.hexaPattern} />
        </div>
      </div>
    </div>
  </Layout>
);
export default Error404;
